import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <pre><code parentName="pre" {...{}}>{`html {
  font-family: -apple-system, 'Segoe UI', 'DejaVu Sans',
    system-ui, sans-serif;
}

code, kbd, pre, samp {
  font-family: 'SF Mono', SFMono-Regular, ui-monospace,
    'DejaVu Sans Mono', Menlo, Consolas, monospace;
}
`}</code></pre>
    <p><strong parentName="p">{`System Fonts`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Font family name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Why`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`-apple-system`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Segoe UI`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Windows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DejaVu Sans`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Linux`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`system-ui`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generic font family`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`sans-serif`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catch-all`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Monospaced Fonts`}</strong></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Font family name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Why`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SF Mono`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`SFMono-Regular`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`ui-monospace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Apple `}<a parentName="td" {...{
              "href": "https://webkit.org/blog/10247/new-webkit-features-in-safari-13-1/"
            }}>{`Safari`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`DejaVu Sans Mono`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitstream Vera Sans Mono (Linux)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Menlo`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Bitstream Vera Sans Mono (Apple)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`Consolas`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Windows`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`monospace`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Generic font family`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      